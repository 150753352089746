import React from "react";
import FeaturedArticle from "../Article/featured_article";
import {ArticleType, DetailsType} from "../../types/Article";

const Projects = () => {

    const project =
        {
            id: 1,
            date: new Date("5-17-2020"),
            headLine: "Dijkstra Pathfinding Algorithm",
            description:
                "This is not my first try at the Dijkstra Pathfinding Algorithm, but I used it as sort of entry point to refresh my React skills.\n" +
                "Play a little around with it, if you want to. I will add a blog entry for this later.\n ",
            featured: true,
            details: DetailsType.none,
            type: ArticleType.webFrame,
            source: "https://blackmarketweb.straussdarman07.now.sh/extern/projects/dijkstra"}

    return(
        <div>
            <FeaturedArticle id={project.id} article={project}/>
        </div>
    )
}

export default Projects;