export enum ArticleType{
    textOnly, image, webFrame
}

export enum DetailsType{
    default, specific, none
}

interface Article{
    id: number;
    date: Date;
    headLine: string;
    description: string;
    featured: boolean;
    type: ArticleType;
    source: string;
    details: DetailsType;
    linkText?: string;
    linkInternal?: boolean;
    linkTarget?: string;
}

export default Article;