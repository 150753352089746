import React from "react";
import styled from "styled-components";
import {useLocation} from "react-router-dom";
import SiteNavLinks from "./site_nav_links";


const Outer = styled.ul`
    flex-grow: 1;
    display:flex;
    list-style: none
`;

const SiteLinks = ({vertical, callback} : {vertical?: boolean, callback: () => void}) => {

    const location = useLocation();

    return(
        <Outer>
            {SiteNavLinks(location, vertical, callback)}
        </Outer>
    )
};

export default SiteLinks;