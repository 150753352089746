import React from "react";
import styled from "styled-components";


const StyledList = styled.ul`
    padding-left: 1.5rem;
    list-style-type: "-    ";
`

const StyledTitle = styled.p`
    font-weight: 500;
`

const StyledDate = styled.p`
    color: #606060;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.85rem;
`

const Experience = ({title, dateFrom, dateTo, work} : {title: string, dateFrom: Date, dateTo?: Date, work: string[]}) => {

    const options = { year: 'numeric', month: 'long' };

    return(
        <div>
            <StyledTitle>{title}</StyledTitle>
            <StyledDate>{dateFrom.toLocaleDateString('en-US', options) } - {(dateTo === undefined ? "present" : dateTo.toLocaleDateString('en-US', options))}</StyledDate>
            <StyledList>{work.map((content, index) => <li key={index}>{content}</li>)}</StyledList>
        </div>
    )
}

export default Experience;