import React, {useRef, useState} from "react";
import Navigation from "../Navigation";
import {BrowserRouter as Router, Route, useLocation} from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import * as CONSTANTS from '../../constants/media-sizes';
import HomePage from "../Home";
import AboutMePage from "../AboutMe";
import HeaderButton from "./header";
import GlobalStyle from "../../theme/globalStyle";
import styled from "styled-components";
import Intro from "../Home/intro";
import Wrapper from "./wrapper";
import Footer from "./footer";
import NavMenuButton from "../Navigation/navigation_menu";
import VerticalNavMenu from "../Navigation/vertical_nav";
import ArticleDetails from "../Article";
import Projects from "../Projects";

require('typeface-roboto')


const Background = styled.div`
    transform: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #212931;
    background-image: url(../../images/overlay.png), linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(../../images/bg_blur.jpg);
    background-size: auto, auto, 100% auto;
    background-position: center, center, top center;
    background-repeat: repeat, no-repeat, no-repeat;
    background-attachment: scroll, scroll, scroll;
    z-index: -1;
`;

const Body = styled.div`

    position: relative;
    margin: 0 auto;
    width: calc(100% - 4rem);
    max-width: 72rem;
    z-index: 2;
    padding-bottom: 2rem;
    
    @media screen and (max-width: ${CONSTANTS.DEVICESIZE.mobileL}){
        width: 100%
    }
    
`

const Content = styled.div`    
    background-color: #ffffff;
`

const App = () =>
{
    return(
        <Router>
            <GlobalStyle/>
            <Site/>
        </Router>
    );
}

const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop)

const Site = () =>
{
    const[hidden, setHidden] = useState(false);

    const[introMounted, setIntroMounted] = useState(false);

    const[verticalNavVisible, setVerticalNavVisible] = useState(false);

    const location = useLocation();

    let isIntro = location.pathname === ROUTES.HOME;

    const myRef = useRef(null);

    const executeScroll = () => scrollToRef(myRef)

    return(
        <Wrapper limitReached={setHidden}>
            <NavMenuButton openNavMenu={() => setVerticalNavVisible(true)}/>
            <VerticalNavMenu visible={verticalNavVisible} close={() => setVerticalNavVisible(false)} scroll={executeScroll}/>
            <Route exact path={ROUTES.HOME} render={() => <Intro hidden={hidden} setIntroMounted={setIntroMounted}/>}/>
            <HeaderButton intro={isIntro}  hidden={!hidden && isIntro} introMounted={introMounted}/>
            <Body>
                <Navigation scroll={executeScroll}/>
                <Content ref={myRef}>
                    <Route exact path={ROUTES.HOME} component={HomePage}/>
                    <Route path={ROUTES.ABOUT_ME} component={AboutMePage} />
                    <Route path={`${ROUTES.ARTICLES}/:id`} component={ArticleDetails}/>
                    <Route path={ROUTES.PROJECTS} component={Projects}/>
                </Content>
                <Footer/>
            </Body>
            <Background/>
        </Wrapper>
    );
}

export default App;