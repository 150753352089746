import React, {useEffect} from "react";
import styled, {css} from "styled-components";
import AnchorButton from "../Buttons/anchor_button";
import {ArrowAltCircleDown} from '@styled-icons/fa-regular/ArrowAltCircleDown'
import * as CSS from '../../constants/css-constants'
import * as CONSTANTS from "../../constants/media-sizes";


const Container = styled.div<{ hidden?: boolean }>`   
    min-height: 100vh;
    ${CSS.DisplayFlex}
    ${CSS.FlexDirectionColumn}
    ${CSS.JustifyContentFlexEnd}
    opacity: 0;
    ${props => !props.hidden && css`opacity: 1`};
    ${props => props.hidden && css`transform: translateY(2rem);`} 
    transition: opacity 0.5s ease, transform 1s ease;
    position: relative;
    cursor: default;
    text-align: center;
    z-index: 1;     
    
    @media screen and (max-width: ${CONSTANTS.DEVICESIZE.desktopS}){
        
        min-height: 90vh;
    }
    
    @media screen and (max-width: ${CONSTANTS.DEVICESIZE.tablet}){
        min-height: 80vh;
    }
    
    
`;
const StyledDiv = styled.div`

    align-items: center;  
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: default;
    text-align: center;
    z-index: 1; 
    padding: 0rem 4rem 6rem 4rem;
    @media screen and (max-width: ${CONSTANTS.DEVICESIZE.tablet}){
        padding: 3rem 2rem 1rem 2rem;
    }
    @media screen and (max-width: ${CONSTANTS.DEVICESIZE.desktopS}){
        padding: 4rem 4rem 2rem 4rem;
    }
`;
const Header = styled.h1`
    color: white;
    font-size: 5rem;
    line-height: 1;
    font-family: Roboto;
    margin: 0 0 2rem 0;
    font-weight: 900;
    letter-spacing: 0.075em;
    text-transform: uppercase;
    
    @media screen and (max-width: ${CONSTANTS.DEVICESIZE.tablet}){
        font-size: 3.25rem;
        line-height: 1.1;
        margin-bottom: 1rem;
    }
`;
const Paragraph = styled.p`
    font-size: 1.25rem;
    font-style: italic;
    text-align: center;
    color: #ffffff;
    margin: -0.25rem 0 2rem 0;
    
    @media screen and (max-width: ${CONSTANTS.DEVICESIZE.tablet}){
        font-size: 0.7rem;
        margin-top: 0rem;
    }
`;

const StyledAnchroLink = styled(AnchorButton)`
    position: relative;
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    border-radius: 4rem;
    text-indent: 4rem;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    
    @media screen and (max-width: ${CONSTANTS.DEVICESIZE.tablet}){
        display: none;
    }
`;

const ArrowDown = styled(ArrowAltCircleDown)`
    background-color: transparent;
    color: #ffffff;
    border-bottom-color: rgba(255, 255, 255, 0.5)
    position: relative;
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    border-radius: 4rem;
    text-indent: 4rem;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    
    :hover{
        color: #18bfef;
        border-color: #18bfef;
    }
`;


const Intro = ({hidden, setIntroMounted}: { hidden: boolean, setIntroMounted: (val: boolean) => void }) => {

    useEffect(() => {
        let timeout: number = setTimeout(() => setIntroMounted(true), 500);
        return () => {
            setIntroMounted(false);
            clearTimeout(timeout);
        }
    }, [setIntroMounted])


    return (

        <Container id={"intro"} hidden={hidden}>
            <StyledDiv>
                <Header>
                    Thomas Stachl
                </Header>
                <Paragraph>
                    Find a job you enjoy doing, and you will never have to work a day in your life. <br/> Mark
                    Twain
                </Paragraph>
                <StyledAnchroLink anchorId={"#header"}>
                    <ArrowDown>
                        Continue
                    </ArrowDown>
                </StyledAnchroLink>
            </StyledDiv>
        </Container>
    )
}

export default Intro