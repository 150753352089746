import React from "react";
import styled from "styled-components";
import {StyledIconBase} from "@styled-icons/styled-icon";
import {Bars} from "@styled-icons/fa-solid"
import * as CSS from "../../constants/css-constants"
import * as CONSTANTS from "../../constants/media-sizes"

const MenuButton = styled.button`
    ${CSS.DisplayFlex}
    right: 0;
    position: fixed;
    z-index: 4;
    padding: 0.5rem;
    margin: 1rem;
    background-color: #ffffffbb;
    border: none;
    box-shadow: 0 0.125rem 0.75rem 0 rgba(30, 37, 45, 0.25);
    cursor: pointer;
    display: none;
    
    :focus
    {
        outline: none;
    }
  
  @media screen and (max-width: ${CONSTANTS.DEVICESIZE.desktopS}){
        display: block;
    }
`

const StyledSpan = styled.span`
    padding-left: 0.5rem;
    text-transform: uppercase;
    align-self: center;
    color: #000000;
    font-weight: 900;
`

const IconWrapper = styled.div<{color?: string}>`
    ${CSS.DisplayFlex}
    align-items: center;
    
    ${StyledIconBase}{
        color: ${props => props.color === undefined? "#000000" : props.color};
        width: 0.75rem;
        height: 0.75rem;
        
        :hover{
            border-bottom-color: transparent;
            color: #18bfef;
        }
    }
`

const NavMenuButton = ({openNavMenu} : {openNavMenu: () => void}) => {

    return(
        <MenuButton onClick={() => openNavMenu()}>
            <IconWrapper>
                <Bars>
                    Menu
                </Bars>
                <StyledSpan>Menu</StyledSpan>
            </IconWrapper>
        </MenuButton>
    )
}

export default NavMenuButton;