import React from 'react';
import * as CSS from '../../constants/css-constants';
import IconLinks from "../Links/icon_links";
import styled from "styled-components";
import SiteLinks from "./links";
import * as CONSTANTS from "../../constants/media-sizes";

const Nav = styled.nav`
    color: #ffffff;
    ${CSS.DisplayFlex}
    -moz-transition: -moz-transform 1s ease, opacity 1s ease;
    -webkit-transition: -webkit-transform 1s ease, opacity 1s ease;
    -ms-transition: -ms-transform 1s ease, opacity 1s ease;
    transition: transform 1s ease, opacity 1s ease;
    background: rgba(255, 255, 255, 0.175);
    height: 4rem;
    line-height: 4rem;
    margin: -4rem auto 0 auto;
    overflow: hidden;
    
    @media screen and (max-width: ${CONSTANTS.DEVICESIZE.desktopS}){
        display: none;
    }
`

const Navigation = ({scroll} : {scroll: () => void}) => {
    return (
        <Nav id="nav">
            <SiteLinks callback={scroll}/>

            <IconLinks/>
            {/*
                <ul>
            <li>
                <Link to={ROUTES.HOME}>Home</Link>
            </li>
            <li>
                <Link to={ROUTES.ABOUT_ME}>About Me</Link>
            </li>
        </ul>
            */}
        </Nav>
    );
}

export default Navigation;