import React from "react";
import styled, {css} from "styled-components";
import * as CSS from "../../constants/css-constants";
import Link from "../Links/link";
import * as CONSTANTS from "../../constants/media-sizes";

const Header = styled.header<{intro?: boolean, hidden?: boolean}>`
    color: #ffffff;   
    ${CSS.AlignItemsCenter}  
    ${CSS.DisplayFlex}
    ${CSS.FlexDirectionColumn}
    ${CSS.JustifyContentCenter}
    ${CSS.UserSelectNone}
    height: 20rem;
    pointer-events: none; 
    ${props => props.intro && css`margin-top: -20rem;`}
    position: relative;
    padding-bottom: 8rem;
    z-index: 2;
    
    @media screen and (max-width: ${CONSTANTS.DEVICESIZE.tablet}){
        padding-bottom: 2rem;
    }
`

const HeaderLink = styled(Link)<{animate?: boolean, intro?: boolean, hidden?: boolean, mounted?: boolean}>` 

    ${props => props.intro && props.hidden && css`opacity: 0; visibility: hidden;`}
    ${props => !props.hidden && css`opacity: 1; visibility: visible;`}
    ${props => props.intro && props.hidden && css`transform: translateY(2rem);`} 
    
    ${props => props.animate && props.intro && css`transition: visibility 0.5s ease, opacity 0.5s ease, transform 0.5s ease;`}
    pointer-events: auto;
    border-style: solid;
    border-color: #ffffff;
    border-width: 5px !important;
    font-family: "Source Sans Pro", Helvetica, sans-serif;
    font-size: 2.25rem;
    font-weight: 900;
    letter-spacing: 0.075em;
    line-height: 1;
    padding: 1rem 1.75rem;
    text-transform: uppercase;
    color: #ffffff;
    
    :hover
    {
        border-color: #18bfef;
    }
    @media screen and (max-width: ${CONSTANTS.DEVICESIZE.tablet}){
        font-size: 25px;
        padding: 1rem;
    }

`
const HeaderButton = ({hidden, intro, introMounted} : {hidden:boolean, intro?:boolean, introMounted?: boolean}) =>
{
    return(
        <Header id="header" hidden={hidden} intro={intro}>
            <HeaderLink to="/" className="logo" intro={intro} hidden={hidden} animate={introMounted}>Thomas Stachl</HeaderLink>
        </Header>
    );
};

export default HeaderButton;