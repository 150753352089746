import React from "react";
import styled from "styled-components"
import LinkButton from "../Buttons/link_button";
import Date from "./date"
import ImageLink from "../Links/image_link";
import Link from "../Links/link";
import Article, {ArticleType, DetailsType} from "../../types/Article";
import * as CONSTANTS from "../../constants/media-sizes";
import * as ROUTES from "../../constants/routes";
import WebFrame from "../Links/web_frame";

const StyledArticle = styled.article<{first?: boolean}>`
    text-align: center;
    padding: 8rem 8rem 6rem 8rem;
    
    ${props => !props.first && "border-top: solid 2px #eeeeee;"}
    
    @media screen and (max-width: ${CONSTANTS.DEVICESIZE.desktopM}){
        padding: 6rem 4rem 4rem 4rem;
    }
    
    @media screen and (max-width: ${CONSTANTS.DEVICESIZE.tablet}){
        padding: 4rem 2rem 2rem 2rem;
    }
    
    
`;

const Header = styled.header`
    margin: 0 0 4rem 0;
    
     @media screen and (max-width: ${CONSTANTS.DEVICESIZE.tablet}){
        margin: 0 0 2rem 0;
    }
`;

const HeadLine = styled.h2`
    font-size: 4rem;
    line-height: 1.1;
    margin: 0 0 2rem 0;
    text-transform: uppercase;
    
    text-align: center;
    cursor: default;
    @media screen and (max-width: ${CONSTANTS.DEVICESIZE.tablet}){
        font-size: 2rem;
        line-height: 1.2;
        margin: 0 0 1.5rem 0;
    }
`;

const SubHeader = styled.p`
    margin-bottom: 0;
    margin-top: 0;
    font-size: 1rem;
    font-style: italic;
    white-space: pre-line;
`;

const StyledHeaderLink = styled(Link)<{disabled?: boolean}>`
    border-bottom: none;
    white-space: pre-line;
    
    ${props => props.disabled &&
    `   cursor: default;
        :hover
        {
            color: inherit;
        }`
    }
`

const FeaturedArticle = ({id, article} : {id: number, article: Article}) =>
{
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };

    const articleSource = article.details === DetailsType.default ? `${ROUTES.ARTICLES}/${article.id}` : "/";

    let linkSource: string = "/";

    switch(article.details)
    {
        case DetailsType.default:
            linkSource = `${ROUTES.ARTICLES}/${article.id}`;
            break;
        case DetailsType.none:
            linkSource = "/";
            break;
        case DetailsType.specific:
            linkSource = article.linkTarget !== undefined ? article.linkTarget : "/";
            break;
    }


    const linkText = article.details === DetailsType.specific && article.linkText !== undefined ? article.linkText : "Full Story";

    return (
        <StyledArticle first={id === 0}>
            <Header>
                <Date>
                    {article.date.toLocaleDateString('de-DE', options)}
                </Date>
                <HeadLine>
                    <StyledHeaderLink disabled={article.details !== DetailsType.default} to={articleSource}>{article.headLine}</StyledHeaderLink>
                </HeadLine>
                <SubHeader>
                    {article.description}
                </SubHeader>
            </Header>
            {article.type === ArticleType.image &&
                <ImageLink source={article.source} to={articleSource} disabled={article.details !== DetailsType.default}/>
            }
            {article.type === ArticleType.webFrame &&
                <WebFrame source={article.source}></WebFrame>
            }
            {article.details !== DetailsType.none &&
                <LinkButton to={linkSource}>
                    {linkText}
                </LinkButton>
            }
        </StyledArticle>
    )
}
export default FeaturedArticle;