import React from "react";
import IconLinks from "../Links/icon_links";
import styled from "styled-components";
import * as CSS from "../../constants/css-constants"
import Link from "../Links/link";
import * as CONSTANTS from "../../constants/media-sizes";


const StyledFooter = styled.footer`
    ${CSS.DisplayFlex}
    background-color: #f5f5f5;
    color: #909498;
    
    @media screen and (max-width: ${CONSTANTS.DEVICESIZE.desktopS}){
        display: block;
    }
`

const StyledSection = styled.section`
    ${CSS.DisplayFlex}
    ${CSS.FlexDirectionColumn}
    padding: 0;
    border-left: 0;
    flex-basis: 50%;
    flex-grow: 1;
    flex-shrink: 1;
    border-left: solid 2px #e2e2e2;
    :first-child{border-left: 0}
    
    @media screen and (max-width: ${CONSTANTS.DEVICESIZE.desktopS}){
        border-left: 0;
        border-top: solid 2px #e2e2e2;
        :first-child{border-top: 0}
    }
    
    
`

const InnerSection = styled.section`
    padding: 3rem 4rem 4rem 4rem;
    ${CSS.DisplayFlex}
    align-items: center;
    
    @media screen and (max-width: ${CONSTANTS.DEVICESIZE.desktopM}){
        ${CSS.FlexDirectionColumn}
        padding: 1rem 1rem 2rem 1rem;
    }
`

const SectionHeader = styled.h3`
    
    width: 6rem;    
    font-weight: 900;
    line-height: 1.5;
    letter-spacing: 0.075em;
    text-transform: uppercase;
    color: #717981;
    
    @media screen and (max-width: ${CONSTANTS.DEVICESIZE.desktopM}){
        padding: 0rem 0rem 1rem 0rem;
        text-align: center;
    }
`
const SectionContent = styled.p`

`

const StyledSocialLink = styled(IconLinks)`

    
`

const Footer = () =>
{

    return(
        <StyledFooter id={"footer"}>
            <StyledSection id={"email"}>
                <InnerSection>
                    <SectionHeader>Email</SectionHeader>
                    <SectionContent>
                        <Link external to={"mailto:stachl.thomas@googlemail.com"}> stachl.thomas@googlemail.com </Link>
                    </SectionContent>
                </InnerSection>
            </StyledSection>
            <StyledSection id={"social"}>
                <InnerSection>
                    <SectionHeader>Social</SectionHeader>
                    <StyledSocialLink color={"#717981"} outline={"#717981"}/>
                </InnerSection>
            </StyledSection>
        </StyledFooter>
    )
}

export default Footer;