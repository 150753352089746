import React, {useEffect} from "react";
import FeaturedArticle from "../Article/featured_article";
import styled from "styled-components";
import Article, {ArticleType, DetailsType} from "../../types/Article";
import * as ROUTES from "../../constants/routes"

//require('typeface-roboto')

const Main = styled.div`

`;



const fetchArticles = async (): Promise<Article[]> => {
    const data : Article[] =
        [   {   id: 0,
                date: new Date("5-17-2020"),
                headLine: "Welcome to my Website",
                description:
                    "Welcome everyone, this is my first and until now only Website\n" +
                    "I will use this as some sort of Project Portfolio and maybe as a little Blog. I will try to extend this as time allows me to.\n " +
                    "Thank your for visiting and have a nice day!",
                featured: true,
                details: DetailsType.specific,
                linkText: "About Me",
                linkInternal: true,
                linkTarget: ROUTES.ABOUT_ME,
                type: ArticleType.textOnly,
                source: ""},
            {   id: 1,
                date: new Date("5-17-2020"),
                headLine: "Dijkstra Pathfinding Algorithm",
                description:
                    "This is not my first try at the Dijkstra Pathfinding Algorithm, but I used it as sort of entry point to refresh my React skills.\n" +
                    "Play a little around with it, if you want to. I will add a blog entry for this later.\n ",
                featured: true,
                details: DetailsType.none,
                type: ArticleType.webFrame,
                source: "https://blackmarketweb.straussdarman07.now.sh/extern/projects/dijkstra"}];

    return data;
}

const HomePage = () =>
{
    const[articles, setArticles] = React.useState<Article[]>([]);

    useEffect(() => {fetchArticles().then(fetchedArticles => setArticles(fetchedArticles))}, [])

    return(
        <Main>
            {articles.map((article, id) => <FeaturedArticle key={id} id={id} article={article}/>)}
        </Main>
    )
}

export default HomePage;