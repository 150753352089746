import {useParams} from "react-router";
import React from "react";

const ArticleDetails = () => {

    const {id} = useParams()



    return(
        <div>
            Article {id}
        </div>)
}

export default ArticleDetails;