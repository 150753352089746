import React from "react";
import {Link as BrowserLink} from 'react-router-dom'
import styled from "styled-components";

const StyledLink = styled(BrowserLink)`
    font-family: 'Roboto', sans-serif;
    -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    -ms-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    border-bottom: dotted 1px;
    text-decoration: none;
    color: #212931;
    border-bottom-color: rgba(33, 41, 49, 0.5);
    
    :hover{
        border-bottom-color: transparent;
        color: #18bfef;
    }
`;

const InternalLink = ({to, className, callback, children} : {to:string, className?: string, callback?: () => void, children: React.ReactNode}) =>
    <StyledLink className={className} onClick={() => callback?.()} to={to}>
            {children}
    </StyledLink>

const ExternalLink = ({href, className, callback, children} : {href:string, className?: string, callback?: () => void, children: React.ReactNode}) =>
    <StyledLink as={"a"} className={className} onClick={() => callback?.()} href={href}>
        {children}
    </StyledLink>

const Link = ({external, to, className, callback, children} : {external?: boolean, to: string, callback?: () => void, className?: string, children: React.ReactNode}) =>
    external ? <ExternalLink href={to} callback={callback} className={className}>{children}</ExternalLink> : <InternalLink to={to} className={className} callback={callback}>{children}</InternalLink>


export default Link;