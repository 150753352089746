import React from "react";
import styled from "styled-components";
import * as CONSTANTS from "../../constants/media-sizes";
import Experience from "./experience-blocks";
import Education from "./education-blocks";

const HeadLine = styled.h2`
    font-size: 4rem;
    line-height: 1.1;
    margin: 2rem 0 2rem 0;
    text-transform: uppercase;
    text-align: center;
    cursor: default;
    @media screen and (max-width: ${CONSTANTS.DEVICESIZE.tablet}){
        font-size: 2rem;
        line-height: 1.2;
        margin: 0 0 1.5rem 0;
    } 
`;

const Intro = styled.div`
    text-align: justify;
    line-height: 2;
`

const Container = styled.div`
    padding: 1rem 8rem 2rem 8rem;
    border-top: solid 2px #eeeeee;

    @media screen and (max-width: ${CONSTANTS.DEVICESIZE.desktopM}){
        padding: 1rem 4rem 2rem 4rem;
    }
    
    @media screen and (max-width: ${CONSTANTS.DEVICESIZE.tablet}){
        padding: 1rem 2rem 2rem 2rem;
    }
 `
const Wrapper = styled.div`
    :first-child{border-top:none}
`


const AboutMePage = () =>
{
    return(
        <Wrapper>
            <Container>
                <HeadLine>About Me</HeadLine>
                <Intro>I grew up in a small village in Bavaria, Germany. I started an apprenticeship at a municipality
                    nearby but noticed that this wasn't the kind of work I enjoyed or was passionated about. So I decided to got to university.
                    Unsure what to study I thought about, what I was missing at my apprenticeship and what I enjoyed doing while growing up.
                    Videogames we're always a part of my life, as long as i can remember, and I wanted to know more about how they function.
                    This drove me to choose the field of Computer Science, which became in the end not only my job, but more my passion.</Intro>
            </Container>
            <Container>
                <HeadLine>Experience</HeadLine>
                <Experience title={"Software Engineer, VOLKE Consulting Engineers GmbH & Co. Planungs KG"}
                            dateFrom={new Date("2019-02-14")}
                            work={["Designed and developed a new .Net/C# Rich-Client application for gathering and converting test data of vehicles"]}/>
                <br/>
                <Experience title={"Working Student, VOLKE Consulting Engineers GmbH & Co. Planungs KG"}
                            dateFrom={new Date("2018-02-14")}
                            dateTo={new Date("2019-01-31")}
                            work={["Maintained and developed an Java enterprise application to automate inhouse processes"]}/>
                <br/>
                <Experience title={"Internship, VOLKE Consulting Engineers GmbH & Co. Planungs KG"}
                            dateFrom={new Date("2017-09-01")}
                            dateTo={new Date("2018-01-31")}
                            work={["Maintained and developed an Java enterprise application to automate inhouse processes"]}/>
                <br/>
                <Experience title={"Working Student, Elektroniksystem- und Logistik GmbH, München"}
                            dateFrom={new Date("2016-09-01")}
                            dateTo={new Date("2017-07-31")}
                            work={["Developed a small help suit for an Java enterprise application"]}/>
            </Container>
            <Container>
                <HeadLine>Education</HeadLine>
                <Education institution={"University of Applied Sciences Munich"}
                           degree={"Bachelor of Science Computer Science"}
                           dateTo={new Date("2019-03-31")}
                           dateFrom={new Date("2015-10-01")}/>
                <br/>
                <Education institution={"Municipality Wartenberg"}
                           degree={"Apprenticeship as administrative professional"}
                           dateTo={new Date("2015-08-31")}
                           dateFrom={new Date("2012-09-01")}/>
                <br/>
                <Education institution={"College of Further Education Erding"}
                           degree={"Entrance qualification for universities of applied sciences"}
                           dateTo={new Date("2010-09-14")}
                           dateFrom={new Date("2012-08-31")}/>
                <br/>
            </Container>
        </Wrapper>
    )
};

export default AboutMePage;
