import React from "react";
import styled from "styled-components";
import IconLinks from "../Links/icon_links";
import {useLocation} from "react-router-dom";
import SiteNavLinks from "./site_nav_links";

const StyledNav = styled.div<{visible?: boolean}>`
    display: block;
    padding: 2.5rem 1.75rem;
    position: fixed;
    max-width: 80%;
    right: 0;
    top: 0;
    width: 20rem;
    height: 100%;
    z-index: 10000;   
    background: #ffffff;
    
    transform:  ${props => props.visible ? "translateX(0)" : "translateX(20rem)"};
    transition: transform 0.5s ease, visibility 0.5s ease;
`

const Nav = styled.nav`
    
`

const Outer = styled.ul`
    display:block;
    list-style: none;
    padding-left:0;
    margin: 0 0 2rem 0;
`;

const StyledButton = styled.button`
    position: absolute;
    top: 0;
    right: 0; 
    padding-right: 1.25rem;
    font-size: 2rem;
    background-color: transparent;
    border: none;
    color: #717981;
`

const SiteLinks = ({callback} : {callback?: () => void}) => {
    const location = useLocation();

    return(
        <Outer>
            {SiteNavLinks(location, true, callback)}
        </Outer>
    )
};

const VerticalNavMenu = ({visible, close, scroll} : {visible: boolean, close: () => void, scroll: () => void}) => {

    return(
        <StyledNav visible={visible} id="navPanel">
            <Nav>
                <SiteLinks callback={() => {scroll(); close();}}/>

                <IconLinks color={"#000000"} outline={"#717981"}/>

                <StyledButton onClick={() => close()}>&times;</StyledButton>
            </Nav>
        </StyledNav>
    )
}

export default VerticalNavMenu;