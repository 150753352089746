import React from "react";
import styled from "styled-components";

const EmbeddedContainer = styled.div`
    position: relative; 
    padding-bottom: 60%; /* ratio 16x9 */
    height: 0; 
    overflow: hidden; 
    width: 100%;
    height: auto;
    
    @media screen and (max-width: 736px)
    {
        padding-bottom: 150%;
    }
`
const StyledIFrame = styled.iframe`
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
    background: #f5f5f5;
    border:#f5f5f5 1px solid;
`;

const WebFrame = ({source}: {source: string}) => {
    return (
        <EmbeddedContainer>
            <StyledIFrame src={source}/>
        </EmbeddedContainer>);
}

export default WebFrame;