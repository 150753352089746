import * as ROUTES from "../../constants/routes";
import React from "react";
import styled from "styled-components";
import Link from "../Links/link";
import { Location } from 'history';

const Inner = styled.li<{active?: boolean, vertical?: boolean}>`

    background-color: ${props => props.active ? "#ffffff" : "transparent"};    
    
    border-top: ${props => props.vertical? "solid 2px #eeeeee" : "none"};
    
    :first-child{
        border-top: 0;
    }
    
    :hover{
        background-color: ${props => props.active ? "#ffffff" : "#ffffff1a"};
    }
`;

const StyledLink = styled(Link)<{active?: boolean, vertical?: boolean}>`
    
    font-size: 0.8rem;
    display: block;
    outline: none;
    padding: ${props => props.vertical? "0.75rem 0.5rem" : "0 2rem"};
    text-transform: uppercase;
    border-bottom: none;
    color: ${props => props.active ? "black" : (props.vertical ?  "black" : "white")};
    font-weight: 900;
    letter-spacing: 0.075em;
    
    :hover{
        color: ${props => props.active ? "#18bfef" : (props.vertical ?  "black" : "white")};
    }
`;

const Home = ({location, vertical, onClickCallback} : {location: Location, vertical?: boolean, onClickCallback?: () => void}) => {

    return(
        <Inner active={location.pathname === ROUTES.HOME} vertical={vertical}>
            <StyledLink active={location.pathname === ROUTES.HOME} vertical={vertical} to={ROUTES.HOME} callback={onClickCallback}>Home</StyledLink>
        </Inner>
    )
}

const AboutMe = ({location, vertical, onClickCallback} : {location: Location, vertical?: boolean, onClickCallback?: () => void}) => {

    return(
        <Inner active={location.pathname === ROUTES.ABOUT_ME} vertical={vertical}>
            <StyledLink active={location.pathname === ROUTES.ABOUT_ME} vertical={vertical} to={ROUTES.ABOUT_ME} callback={onClickCallback}>About Me</StyledLink>
        </Inner>
    )
}

const Projects = ({location, vertical, onClickCallback} : {location: Location, vertical?: boolean, onClickCallback?: () => void}) => {

    return(
        <Inner active={location.pathname === ROUTES.PROJECTS} vertical={vertical}>
            <StyledLink active={location.pathname === ROUTES.PROJECTS} vertical={vertical} to={ROUTES.PROJECTS} callback={onClickCallback}>Projects</StyledLink>
        </Inner>
    )
}

const SiteNavLinks = (location:  Location, vertical?: boolean, onClickCallback?: () => void) : JSX.Element[]=>  {
    return [<Home key={"Home"} location={location} vertical={vertical} onClickCallback={onClickCallback}/>,
            <AboutMe key={"AboutMe"} location={location} vertical={vertical} onClickCallback={onClickCallback}/>,
            <Projects key={"AboutMe"} location={location} vertical={vertical} onClickCallback={onClickCallback}/>];
}

export default SiteNavLinks;