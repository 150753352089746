import React from "react";
import styled from "styled-components";
const StyledTitle = styled.p`
    font-weight: 500;
`

const StyledDate = styled.p`
    color: #606060;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.85rem;
`

const Education = ({institution, degree, dateFrom, dateTo} : {institution: string, degree: string, dateFrom: Date, dateTo?: Date}) => {

    const options = { year: 'numeric', month: 'long' };

    return(
        <div>
            <StyledTitle>{institution} - {degree}</StyledTitle>
            <StyledDate>{dateFrom.toLocaleDateString('en-US', options) } - {(dateTo === undefined ? "present" : dateTo.toLocaleDateString('en-US', options))}</StyledDate>
        </div>
    )
}

export default Education;