import React from "react";
import Link from "./link";
import styled from "styled-components";

const StyledLink = styled(Link)<{disabled?: boolean}>`
    margin: 4rem 0;
    width: 100%;
    overflow: hidden;
    border: 0;
    position: relative;
    
    ${props => props.disabled &&
    `   cursor: default;
        :hover
        {
            color: inherit;
        }`
    }
`;


const StyledImage = styled.img`
    width: 100%;
`;

const ExternalImageLink = ({className, source, to, disabled} : {className?: string, source: string, to: string, disabled?: boolean}) =>
    <StyledLink external className={className} to={to} disabled={disabled}>
        <StyledImage src={source}/>
    </StyledLink>

const InternalImageLink = ({className, source, to, disabled} : {className?: string, source: string, to: string, disabled?: boolean}) =>
    <StyledLink as={Link} to={to} className={className} disabled={disabled}>
        <StyledImage src={source}/>
    </StyledLink>

const ImageLink = ({className, source, to, external, disabled} : {className?: string, source: string, to: string, external?: boolean, disabled?: boolean}) =>
    external ? <ExternalImageLink className={className} to={to} source={source} disabled={disabled}/> : <InternalImageLink className={className} to={to} source={source} disabled={disabled}/>;

export default ImageLink;