import React from "react";
import styled from "styled-components";
import * as CONSTANTS from "../../constants/media-sizes";


const StyledDate = styled.span`
    font-size: 1rem;
    margin: -2rem 0 4rem 0;
    display: block;
    position: relative;
    
    ::before, ::after
    {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        width: calc(50% - 6rem);
        border-top: solid 2px;
        border-top-color: #eeeeee;
        @media screen and (max-width: ${CONSTANTS.DEVICESIZE.tablet}){
            width: calc(50% - 3.5rem);
        }
    }
    
    ::before
    {
        left: 0;
    }
    
    ::after
    {
        right: 0;
    }
    
    @media screen and (max-width: ${CONSTANTS.DEVICESIZE.tablet}){
        margin-top: -1rem;
        margin-bottom: 2rem;
        
    }
`;

const Date = ({children} : {children: React.ReactNode}) => <StyledDate>{children}</StyledDate>

export default Date;