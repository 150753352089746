import React from "react";
import styled from "styled-components";
import Link from "../Links/link";

const Outer = styled.ul`
    justify-content: center;
    width: 100%;
    display: flex;
    cursor: default;
    list-style: none;
    padding-left: 0;  
    margin: 4rem 0 2rem 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
`;
const Inner = styled.li`
    cursor: default;
    list-style: none;
`;

const StyledLink = styled(Link)`
    text-align: center;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 0.075em;
    white-space: nowrap;       
    display: inline-block;
    border: 0;
    transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, color 0.2s ease-in-out;
    line-height: 3.5rem;
    padding: 0 2.75rem;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #212931;
    font-size: 0.9rem;
    :hover
    {
        box-shadow: inset 0 0 0 2px #18bfef;
    }
`

const LinkButton = ({to, children} : {to: string, children: React.ReactNode}) =>
{
    return(
        <Outer>
            <Inner>
                <StyledLink to={to}>
                    {children}
                </StyledLink>
            </Inner>
        </Outer>
    );
}

export default LinkButton;