import React from "react";
import styled from "styled-components";
import {StyledIconBase} from "@styled-icons/styled-icon"
import {Twitter, Instagram, Linkedin, Github} from "@styled-icons/fa-brands"
import Link from "./link";

const Outer = styled.ul`
    flow-grow: 0;
    flex-shrink: 0;
`;

const Inner = styled.li`
    display: inline-block;
    padding: 0 0.5rem 0 0;
    vertical-align: middle;
`;

const StyledLink = styled(Link)<{outline?: string}>`
    width: 2.25rem;
    height: 2.25rem;
    line-height: 2.25rem;
    display: inline-block;
    text-align: center;
    border-radius: 100%;
    font-size: 1.25rem;
    border: 1px solid ${props => props.outline === undefined || props.outline === "" ? "transparent" : props.outline};

`;

const IconWrapper = styled.div<{color?: string}>`
    ${StyledIconBase}{
        color: ${props => props.color === undefined? "#ffffff" : props.color};
        width: 1.25rem;
        height: 1.25rem;
        
        :hover{
            border-bottom-color: transparent;
            color: #18bfef;
        }
    }
`

const IconLinks = ({color, outline} : {color?: string, outline?: string}) =>
{
    return(
        <Outer>
            <Inner>
                <StyledLink outline={outline} external to="https://twitter.com/thomas_stachl">
                    <IconWrapper color={color}>
                        <Twitter>
                            Twitter
                        </Twitter>
                    </IconWrapper>
                </StyledLink>
            </Inner>
            <Inner>
                <StyledLink outline={outline} external to="https://www.instagram.com/thomas.stachl">
                    <IconWrapper color={color}>
                        <Instagram>
                            Instagram
                        </Instagram>
                    </IconWrapper>
                </StyledLink>
            </Inner>
            <Inner>
                <StyledLink outline={outline} external to="https://www.linkedin.com/in/thomas-stachl-578aa21a2/">
                    <IconWrapper color={color}>
                        <Linkedin>
                            LinkedIn
                        </Linkedin>
                    </IconWrapper>
                </StyledLink>
            </Inner>
            <Inner>
                <StyledLink outline={outline} external to="https://github.com/StraussDarman07">
                    <IconWrapper color={color}>
                        <Github>
                            Github
                        </Github>
                    </IconWrapper>
                </StyledLink>
            </Inner>
        </Outer>
    );
};

export default IconLinks;