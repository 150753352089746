import React, {useEffect} from "react";
import styled from "styled-components";

const StyleWrapper = styled.div`
`

const Wrapper = ({children, limitReached} : {children: React.ReactNode, limitReached: (val: boolean) => void}) => {
    let threshold = 25;

    useEffect(() => {
        function handleScrollChange(ev: Event) {

            let vH = window.innerHeight / 100;
            if(window.scrollY >= vH * threshold)
                limitReached(true);
            else
                limitReached(false);
        }

        window.addEventListener("scroll", handleScrollChange);

        return function cleanup() {
            window.removeEventListener("scroll", handleScrollChange);
        };
    });

    return(
        <StyleWrapper id={"wrapper"} >
            {children}
        </StyleWrapper>
    )
}

export default Wrapper;