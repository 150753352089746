import { createGlobalStyle } from 'styled-components';
import * as CONSTANTS from "../constants/media-sizes";

const GlobalStyle = createGlobalStyle`
    

    body 
    {
        margin: 0;
        padding: 0;
    }
 
    code 
    {
        font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
    }
  
    html, body, div, span, applet, object,
    iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
    pre, a, abbr, acronym, address, big, cite,
    code, del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var, b,
    u, i, center, dl, dt, dd, ol, ul, li, fieldset,
    form, label, legend, table, caption, tbody,
    tfoot, thead, tr, th, td, article, aside,
    canvas, details, embed, figure, figcaption,
    footer, header, hgroup, menu, nav, output, ruby,
    section, summary, time, mark, audio, video 
    {
        margin: 0;
        padding: 0;
        border: 0;
        color: #212931;
        font-family: Roboto;
    }

    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section 
    {
        display: block;
    }
    
    a
    {
        text-decoration: none;
    }
    
    html
    {
        scroll-behavior: smooth;
        font-size: 16pt;
        box-sizing: border-box;
        
        @media screen and (max-width: ${CONSTANTS.DEVICESIZE.desktopS}){
            font-size: 12pt;
        }
    }
    
        *, *:before, *:after{
        box-sizing:inherit;
    }
`;

export default GlobalStyle;