import React from "react";
import styled from "styled-components";
import Link from "../Links/link";

const Outer = styled.ul`
    display: flex;
    cursor: default;
    list-style: none;
    margin: 0 0 2rem -1rem;
    padding-left: 0;
`;

const Inner = styled.li`
    padding: 0 0 0 1rem;
    vertical-align: middle;
`;

const AnchorLink = styled(Link)`
`;

const AnchorButton = ({className, anchorId, children} : {className?: string, anchorId: string, children: React.ReactNode}) => {
    return(
        <Outer>
            <Inner>
                <AnchorLink className={className} external to={anchorId}>
                    {children}
                </AnchorLink>
            </Inner>
        </Outer>
    );
}

export default AnchorButton;